import React, { useState } from 'react';
import {
  Box,
  Container,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Alert,
  IconButton,
  Tooltip,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as XLSX from 'xlsx';

interface ExtractedData {
  fullName: string;
  company: string;
  email: string;
  confidence: number;
  isDuplicate: boolean;
}

type ExportFormat = 'csv' | 'xlsx';

function App() {
  const [inputText, setInputText] = useState('');
  const [extractedData, setExtractedData] = useState<ExtractedData[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [exportFilename, setExportFilename] = useState('extracted_data');
  const [exportFormat, setExportFormat] = useState<ExportFormat>('csv');
  const [editingCell, setEditingCell] = useState<{ index: number; field: keyof ExtractedData } | null>(null);
  const [editValue, setEditValue] = useState('');

  const validateEmail = (email: string): boolean => {
    // Simple but effective email validation regex
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return emailRegex.test(email.toLowerCase());
  };

  const calculateConfidence = (emailIndex: number, nameIndex: number, companyIndex: number, textLength: number): number => {
    // Calculate confidence based on proximity and presence of data
    let score = 100;

    // Proximity penalty (max 30 points deduction)
    const nameDistance = Math.abs(emailIndex - nameIndex);
    const companyDistance = Math.abs(emailIndex - companyIndex);
    const proximityPenalty = Math.min(30, (nameDistance + companyDistance) / textLength * 100);
    score -= proximityPenalty;

    // Missing data penalty
    if (nameIndex === -1) score -= 40;
    if (companyIndex === -1) score -= 20;

    return Math.max(0, Math.round(score));
  };

  const extractInformation = (text: string) => {
    try {
      // Regular expressions for different patterns
      const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
      const companyPattern = /[A-Z][a-zA-Z\s]*(?:Inc\.|LLC|Ltd\.|Corp\.|Corporation|Company|Technologies|Solutions|Group)/g;
      const namePattern = /[A-Z][a-zA-Z]+(?:\s+[A-Z][a-zA-Z]+){1,2}/g;  // Two or three capitalized words

      // Helper function to validate name format
      const validateName = (name: string): boolean => {
        const words = name.split(/\s+/);
        return (
          words.length >= 2 && 
          words.length <= 3 && 
          words.every(word => word.match(/^[A-Z][a-zA-Z]+$/))
        );
      };

      // Find all matches with their line numbers
      const emails = Array.from(text.matchAll(emailPattern), m => ({
        value: m[0],
        index: m.index || 0,
        lineNumber: text.slice(0, m.index).split('\n').length - 1
      }));

      const names = Array.from(text.matchAll(namePattern), m => ({
        value: m[0],
        index: m.index || 0,
        lineNumber: text.slice(0, m.index).split('\n').length - 1
      }));

      const companies = Array.from(text.matchAll(companyPattern), m => ({
        value: m[0],
        index: m.index || 0,
        lineNumber: text.slice(0, m.index).split('\n').length - 1
      }));

      // Track processed emails and names for duplicate detection
      const processedEmails = new Set<string>();
      const processedNames = new Set<string>();
      const data: ExtractedData[] = [];

      emails.forEach(({ value: email, index: emailIndex, lineNumber: emailLine }) => {
        // Validate email
        if (!validateEmail(email)) return;

        // Find closest name within 3 lines
        let closestName = '';
        let closestNameIndex = -1;
        let closestDistance = Infinity;
        
        names.forEach(({ value: name, index: nameIndex, lineNumber: nameLine }) => {
          // Skip if name is already used or invalid
          if (processedNames.has(name) || !validateName(name)) return;

          const lineDistance = Math.abs(emailLine - nameLine);
          const charDistance = Math.abs(emailIndex - nameIndex);
          
          // Only consider names within 3 lines and prefer closer ones
          if (lineDistance <= 3 && charDistance < closestDistance) {
            closestDistance = charDistance;
            closestName = name;
            closestNameIndex = nameIndex;
          }
        });

        // Find closest company
        let closestCompany = '';
        let closestCompanyIndex = -1;
        closestDistance = Infinity;
        
        companies.forEach(({ value: company, index: companyIndex }) => {
          const distance = Math.abs(emailIndex - companyIndex);
          if (distance < closestDistance) {
            closestDistance = distance;
            closestCompany = company;
            closestCompanyIndex = companyIndex;
          }
        });

        // Calculate confidence score - lower if no name found
        const confidence = calculateConfidence(
          emailIndex, 
          closestNameIndex, 
          closestCompanyIndex, 
          text.length
        );

        // Check for duplicate email
        const isDuplicate = processedEmails.has(email.toLowerCase());
        processedEmails.add(email.toLowerCase());

        // If we found a name, mark it as used
        if (closestName) {
          processedNames.add(closestName);
        }

        // Add entry regardless of name presence, but with adjusted confidence
        data.push({
          fullName: closestName || '',  // Empty string if no name found
          company: closestCompany,
          email: email,
          confidence: closestName ? confidence : Math.min(confidence, 40), // Lower confidence if no name
          isDuplicate
        });
      });

      // Sort by confidence (highest first) and filter out duplicates if needed
      const sortedData = data
        .sort((a, b) => b.confidence - a.confidence)
        .filter((item, index, self) => 
          index === self.findIndex(t => t.email.toLowerCase() === item.email.toLowerCase()) || item.isDuplicate
        );

      setExtractedData(sortedData);
      setError(null);
    } catch (err) {
      setError('Failed to parse the content. Please check your input.');
    }
  };

  const handleParse = () => {
    if (!inputText.trim()) {
      setError('Please enter some text to parse');
      return;
    }
    extractInformation(inputText);
  };

  const updateDuplicateStatus = (data: ExtractedData[]) => {
    const emailCount = new Map<string, number>();
    
    // First pass: count emails
    data.forEach(item => {
      const email = item.email.toLowerCase();
      emailCount.set(email, (emailCount.get(email) || 0) + 1);
    });

    // Second pass: mark duplicates
    return data.map(item => ({
      ...item,
      isDuplicate: emailCount.get(item.email.toLowerCase())! > 1
    }));
  };

  const handleDeleteRow = (indexToDelete: number) => {
    const newData = extractedData.filter((_, index) => index !== indexToDelete);
    // Update duplicate status for remaining rows
    const updatedData = updateDuplicateStatus(newData);
    setExtractedData(updatedData);
  };

  const handleExportClick = () => {
    if (extractedData.length === 0) {
      setError('No data to export');
      return;
    }
    setExportFilename('extracted_data');
    setExportDialogOpen(true);
  };

  const handleExport = () => {
    // Define headers type but keep the array mutable for XLSX
    const headers = ['Full Name', 'Company', 'Email', 'Confidence'];

    // Helper function to escape CSV fields properly
    const escapeCSVField = (field: string | number): string => {
      const stringField = String(field);
      if (stringField.includes(',') || stringField.includes('"') || stringField.includes('\n')) {
        // Escape quotes by doubling them and wrap in quotes
        return `"${stringField.replace(/"/g, '""')}"`;
      }
      return stringField;
    };

    // Map data explicitly to each column
    const formattedData = extractedData.map(row => {
      return {
        'Full Name': row.fullName || '',
        'Company': row.company || '',
        'Email': row.email || '',
        'Confidence': row.confidence
      };
    });

    if (exportFormat === 'csv') {
      // CSV Export with proper field escaping
      const csvRows = formattedData.map(row => {
        const fields = [
          escapeCSVField(row['Full Name']),
          escapeCSVField(row['Company']),
          escapeCSVField(row['Email']),
          escapeCSVField(row['Confidence'])
        ];
        return fields.join(',');
      });

      // Add headers with proper escaping
      const headerRow = headers.map(escapeCSVField).join(',');
      const csvContent = [headerRow, ...csvRows].join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${exportFilename.trim() || 'extracted_data'}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      // Excel Export with explicit column mapping
      const wsData = [
        headers,
        ...formattedData.map(row => [
          row['Full Name'],
          row['Company'],
          row['Email'],
          row['Confidence']
        ])
      ];

      const ws = XLSX.utils.aoa_to_sheet(wsData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Extracted Data');
      
      // Auto-size columns
      const maxWidths = wsData.reduce((acc: number[], row) => {
        row.forEach((cell, i) => {
          const cellLength = (cell?.toString() || '').length;
          acc[i] = Math.max(acc[i] || 0, cellLength);
        });
        return acc;
      }, headers.map(h => h.length));

      ws['!cols'] = maxWidths.map(width => ({ width: Math.min(Number(width) + 2, 50) }));

      XLSX.writeFile(wb, `${exportFilename.trim() || 'extracted_data'}.xlsx`);
    }

    setExportDialogOpen(false);
  };

  const getConfidenceColor = (confidence: number): "error" | "warning" | "success" => {
    if (confidence < 40) return "error";
    if (confidence < 70) return "warning";
    return "success";
  };

  const handleStartEditing = (index: number, field: keyof ExtractedData, value: string) => {
    setEditingCell({ index, field });
    setEditValue(value);
  };

  const handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(event.target.value);
  };

  const handleEditComplete = () => {
    if (editingCell) {
      const { index, field } = editingCell;
      const newData = [...extractedData];
      
      // Validate email if editing email field
      if (field === 'email' && !validateEmail(editValue)) {
        setError('Invalid email format');
        return;
      }

      newData[index] = {
        ...newData[index],
        [field]: editValue
      };
      setExtractedData(newData);
      setEditingCell(null);
      setEditValue('');
      setError(null);
    }
  };

  const handleEditKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleEditComplete();
    } else if (event.key === 'Escape') {
      setEditingCell(null);
      setEditValue('');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Contact Information Extractor
      </Typography>

      <Box sx={{ mb: 4 }}>
        <TextField
          fullWidth
          multiline
          rows={6}
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Paste your text or HTML content here..."
          variant="outlined"
        />
      </Box>

      <Box sx={{ mb: 4, display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleParse}
        >
          Parse Content
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleExportClick}
          disabled={extractedData.length === 0}
        >
          Export to CSV/Excel
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            setInputText('');
            setExtractedData([]);
            setError(null);
          }}
        >
          Clear
        </Button>
      </Box>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {/* Export Dialog */}
      <Dialog open={exportDialogOpen} onClose={() => setExportDialogOpen(false)}>
        <DialogTitle>Export Data</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, minWidth: 300 }}>
            <TextField
              autoFocus
              margin="dense"
              label="Filename"
              type="text"
              fullWidth
              variant="outlined"
              value={exportFilename}
              onChange={(e) => setExportFilename(e.target.value)}
              helperText={`Enter filename without ${exportFormat} extension`}
              sx={{ mt: 1 }}
            />
            <FormControl fullWidth>
              <InputLabel id="export-format-label">Export Format</InputLabel>
              <Select
                labelId="export-format-label"
                value={exportFormat}
                label="Export Format"
                onChange={(e) => setExportFormat(e.target.value as ExportFormat)}
              >
                <MenuItem value="csv">CSV (.csv)</MenuItem>
                <MenuItem value="xlsx">Excel (.xlsx)</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setExportDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleExport} variant="contained">Export</Button>
        </DialogActions>
      </Dialog>

      {extractedData.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Email</TableCell>
                <TableCell align="center">Confidence</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {extractedData.map((item, index) => (
                <TableRow 
                  key={index}
                  sx={item.isDuplicate ? { backgroundColor: 'rgba(255, 0, 0, 0.05)' } : undefined}
                >
                  <TableCell>
                    {editingCell?.index === index && editingCell.field === 'fullName' ? (
                      <TextField
                        value={editValue}
                        onChange={handleEditChange}
                        onBlur={handleEditComplete}
                        onKeyDown={handleEditKeyPress}
                        size="small"
                        autoFocus
                        fullWidth
                      />
                    ) : (
                      <Box
                        onClick={() => handleStartEditing(index, 'fullName', item.fullName)}
                        style={{ cursor: 'pointer' }}
                      >
                        {item.fullName}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {editingCell?.index === index && editingCell.field === 'company' ? (
                      <TextField
                        value={editValue}
                        onChange={handleEditChange}
                        onBlur={handleEditComplete}
                        onKeyDown={handleEditKeyPress}
                        size="small"
                        autoFocus
                        fullWidth
                      />
                    ) : (
                      <Box
                        onClick={() => handleStartEditing(index, 'company', item.company)}
                        style={{ cursor: 'pointer' }}
                      >
                        {item.company}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {editingCell?.index === index && editingCell.field === 'email' ? (
                      <TextField
                        value={editValue}
                        onChange={handleEditChange}
                        onBlur={handleEditComplete}
                        onKeyDown={handleEditKeyPress}
                        size="small"
                        autoFocus
                        fullWidth
                        error={!!error}
                        helperText={error}
                      />
                    ) : (
                      <Box
                        onClick={() => handleStartEditing(index, 'email', item.email)}
                        style={{ cursor: 'pointer' }}
                      >
                        {item.email}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={`Confidence Score: ${item.confidence}%`}>
                      <Chip
                        label={`${item.confidence}%`}
                        color={getConfidenceColor(item.confidence)}
                        size="small"
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Delete entry">
                      <IconButton
                        onClick={() => handleDeleteRow(index)}
                        color="error"
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
}

export default App;
